import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'withColon',
  standalone: true,
})
export class WithColonPipe implements PipeTransform {
  transform(value: string | null, ...args: unknown[]): string {
    return value?.length > 0 ? `${value}:` : value;
  }
}
